<template>
    <div class="parallax1" style="width: auto; margin: auto; height: 100vh">
        <v-container class="pt-8 text-start" >
            <h2 class="pa-0 pt-12 ma-0 pb-5 font-weight-bold blue-grey--text text--darken-3"
                :class="isMobile ? 'display-1' : 'display-2'"
            >
                Pàgina no disponible
            </h2>
            <p class="blue-grey--text text-start text--darken-3 title font-weight-medium">
                Sembla que aquesta pàgina no existeix.
                <br>
                Possibles causes:
            </p>
            <ul  class="blue-grey--text text-start text--darken-3 subtitle-1">
                <li>El document pdf no existeix</li>
                <li>Una direcció mal escrita</li>
                <li>Informació desactualizada per nosaltres</li>
            </ul>
            <br>
            <p class="blue-grey--text text--darken-3 body-1">
                En tot cas, aquí tens l'enllaç de la
                <a href="/"
                   class="cyan--text pr-0"
                >
                    pàgina principal.
                </a>
            </p>
        </v-container>
    </div>
</template>

<script>
export default {
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.name === 'md' || this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs'
    }
  }
}
</script>

<style>
    .parallax1 {
    }
</style>
